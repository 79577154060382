import { ProductCategory } from '@/models';
import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';

const BASE_PRODUCT_CATEGORIES_URL = BASE_API_URL+"product_categories"

export enum ProductCategoriesMixinTags {
  Index = 'ProductCategoriesIndes',
  Show = 'ProductCategoryShow',
  Update = 'UpdateProductCategory',
  Create = 'CreateProductCategory',
  Destroy = 'DestroyProductCategory'
}

@Component
export default class ProductCategoriesMixin extends Vue {

  getProductCategories(data: any, tag: string):Promise<ProductCategory> {
    return this.$httpGetQuery(BASE_PRODUCT_CATEGORIES_URL, tag,data);
  }

  getProductCategory(id: string, tag: string) {
    return this.$httpGet(BASE_PRODUCT_CATEGORIES_URL+"/"+id, tag);
  }

  destroyProductCategory(id: string|number, tag: string) {
    return this.$httpDelete(BASE_PRODUCT_CATEGORIES_URL+"/"+id, tag);
  }

  createProductCategory(data: any, tag: string) {
    return this.$httpPost(BASE_PRODUCT_CATEGORIES_URL, tag, data);
  }

  updateProductCategory(id: string, data: FormData, tag: string) {
    return this.$httpPut(BASE_PRODUCT_CATEGORIES_URL+"/"+id, tag, data);
  }
}
