






































































































import { Vue, Component, Mixins, Ref } from 'vue-property-decorator';
import CategoryGroupsMixin, { CategoryGroupsMixinTags } from '@/mixins/http/CategoryGroupsMixin';
import { easync } from '@/utils/http';
import { showErrorToast, showToast } from '@/utils/Toast';
import StoreFrontCategoryGroupsMixin, { StoreFrontCategoryGroupsMixinTags } from '@/mixins/http/StoreFrontCategoryGroupsMixin';
import { ProductCategory, StoreFrontCategoryGroup } from '@/models';
import { RegisterHttp } from '@/utils/Decorators';
import { RequestData } from '@/store/types/RequestStore';
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import ConfirmModal from '@/components/ConfirmModal.vue';
import StoreFrontSelect from '@/components/StoreFront/Select.vue';
import WithErrors from '@/directives/WithErrors';
import Checkbox from '@/components/Checkbox.vue';
import ProductCategoriesMixin, { ProductCategoriesMixinTags } from '@/mixins/http/ProductCategoriesMixin';
import ProductCategorySelect from '@/components/ProductCategory/Select.vue';
import VThumbnail from '@/components/VThumbnail.vue';

@Component({
  components: {
    DataTable,
    ConfirmModal,
    StoreFrontSelect,
    Checkbox,
    ProductCategorySelect,
    VThumbnail
  },
  directives: {
    WithErrors
  }
})
export default class StoreFrontContentCategoryGroupList extends Mixins(
  CategoryGroupsMixin,
  StoreFrontCategoryGroupsMixin,
  ProductCategoriesMixin
) {
  private categoryGroup: CategoryGroupsMixin | null = null;
  private productCategories: ProductCategory[] = [];
  private storeFrontCategoryGroup: StoreFrontCategoryGroup | null = null;

  private selectedStoreFront = '';
  private selectedProductCategory = '';

  @Ref() readonly confirmModal!: ConfirmModal;

  @RegisterHttp(ProductCategoriesMixinTags.Index) productCategoriesRequest!: RequestData;
  @RegisterHttp(StoreFrontCategoryGroupsMixinTags.Index) storeFrontCategoryGroupsRequest!: RequestData;

  get categoryGroupId() {
    return this.$route.params.categoryGroupId;
  }

  get storeFrontId() {
    return this.$route.params.id;
  }

  async created() {
    await this.loadCategoryGroup();
    this.loadProductCategories();
    this.loadAssociatedStoreFrontCategoryGroup();
  }

  get showTag() {
    return `${CategoryGroupsMixinTags.Show}_${this.categoryGroupId}`;
  }

  get productCategoriesLoading() {
    return this.productCategoriesRequest?.loading;
  }

  get storeFrontCategoryGroupsLoadig() {
    return this.storeFrontCategoryGroupsRequest?.loading;
  }

  get columns() {
    return [
      { name: "storeFront", label: "Store front" },
      { name: "categoryGroup", label: "Category group" },
      { name: "actions", label: "" }
    ];
  }

  get productCategoriesColumns() {
    return [
      { name: "image", label: "" },
      { name: "name", label: "Nome" },
      { name: "actions", label: "" }
    ];
  }

  get productCategorySelectFilter() {
    return { notCategoryGroup: true };
  }

  get publishActionText() {
    return this.storeFrontCategoryGroup?.published ? 'Rimuovi' : 'Pubblica';
  }

  private async loadCategoryGroup() {
    const [data, errors] = await easync(this.getCategoryGroup(this.categoryGroupId, this.showTag));

    if (data) {
      this.categoryGroup = data;
    } else {
      this.categoryGroup = null;
      showErrorToast();
    }
  }

  private async loadProductCategories() {
    const payload = {
      filter: {
        categoryGroup: this.categoryGroupId
      }
    }
    const [data, errors] = await easync(
      this.getProductCategories(payload, ProductCategoriesMixinTags.Index)
    );

    if (data) {
      this.productCategories = data;
    } else {
      this.productCategories = [];
      showErrorToast();
    }
  }

  private async loadAssociatedStoreFrontCategoryGroup() {
    const payload = {
      filter: {
        storeFront: this.storeFrontId,
        categoryGroup: this.categoryGroupId
      }
    }

    const [data, errors] = await easync(
      this.getStoreFrontCategoryGroups(payload, StoreFrontCategoryGroupsMixinTags.Index)
    );

    if (data) {
      this.storeFrontCategoryGroup = data[0];
    } else {
      this.storeFrontCategoryGroup = null;
    }
  }

  private async onAttachNewProductCategory() {
    const payload = { categoryGroupId: this.categoryGroupId }; 
    this.modifyProductCategory(this.selectedProductCategory, payload);
  }

  private async onDeattachProductCategory(value: ProductCategory) {
    const payload: any = { categoryGroupId: null };

    const message = "Sei sicuro di dissociare questa categoria di prodotti?";
    const confirmed = await (this.confirmModal as any).confirm(message);
    if (confirmed) {
      this.modifyProductCategory(value.id, payload);
    }
  }

  private async modifyProductCategory(id: string, payload: any) {
    const [data, errors] = await easync(
      this.updateProductCategory(id, payload, ProductCategoriesMixinTags.Update)
    );

    if (errors) {
      showErrorToast();
      return;
    }

    this.selectedProductCategory = '';
    showToast('Operazione avvenuta con successo!');
    this.loadProductCategories();
  }

  private async onPublish() {
    const payload = { published: !this.storeFrontCategoryGroup.published };
    
    const [data, errors] = await easync(
      this.updateStoreFrontCategoryGroup(this.storeFrontCategoryGroup.id, payload, StoreFrontCategoryGroupsMixinTags.Update)
    );

    if (data) {
      showToast('Modifica effettuata!');
      this.loadAssociatedStoreFrontCategoryGroup();
    } else {
      showErrorToast();
    }
  }
}
