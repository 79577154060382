



















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class VThumbnail extends Vue {
  @Prop({ required: true }) readonly imgUrl!: string;
  @Prop({ default: 'lg' }) readonly iconSize!: 'lg' | '2x' | '3x';
}
